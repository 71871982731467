

* {
    box-sizing: border-box;
}
.webp.no-jpeg2000 body {
    background-image: url('../assets/images/bg-1212-night.jpg');
}
.jpeg2000 body {
    background-image: url('../assets/images/bg-1212-night.jpg');
}
.no-webp body {
    background-image: url('../assets/images/bg-1212-night.jpg');
}


body {
    margin: 0;
    background-color: black;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-image: url('../assets/images/bg-1212-night.jpg');
    color: white;
    min-height: 100vh;
    font-family: "Noto Sans CJK TC", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", "微軟正黑體", Helvetica, Arial, "Hiragino Sans GB", "Microsoft YaHei UI", "Microsoft YaHei", sans-serif;
    transition: ease 1s background-image,background-color,color;
}

.webp.no-jpeg2000 body.light_theme {
    background-image: url('../assets/images/bg-1212-day.jpg');
}
.no-webp.jpeg2000 body.light_theme {
    background-image: url('../assets/images/bg-1212-day.jpg');
}
.no-webp body.light_theme {
    background-image: url('../assets/images/bg-1212-day.jpg');
}

body.light_theme {
    /* display: none; */
    /* opacity: 0;
    content: '';
    z-index: -100; */
    background-image: url('../assets/images/bg-1212-day.jpg');
    background-color: #ddefff;
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
    background-attachment: fixed;
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none; */
    /* transition: 1s ease opacity,color; */
}
body.light_theme {
    color: black;
}
body.light_theme::after {
    opacity: 1;
}

a {
    color: aqua;
}
a:visited {
    color: aquamarine;
}


.section {
    background: rgba(0, 0, 0, .45);
    border-radius: 4px;
    padding: 1rem;
    min-height: 3rem;
    width: 80%;
    margin: auto;
    margin-top: 1rem;
    box-shadow: 0 0 4px rgba(3, 3, 3, .8);
    transition: ease 1s;
    transition-property: width, height, margin, padding, opacity, min-height, min-width, transform, background-color,color,box-shadow,-webkit-text-fill-color,text-fill-color;
    /* transform: translateZ(0); */
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
    will-change: transform;
}
.light_theme .section {
    background: rgba(250, 250, 250, .8);
    box-shadow: 0 0 0px rgba(255, 255, 255, .8);
}
.light_theme .section.force_dark {
    background: rgba(0, 0, 0, .45);
}


/* Maybe some day
@supports ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    .section {
        backdrop-filter: blur(3px) brightness(60%);
        -webkit-backdrop-filter: blur(3px) brightness(60%);
    }
}
@supports not ((-webkit-backdrop-filter: blur(2em)) or (backdrop-filter: blur(2em))) {
    .section {
        background: none;
        position: relative;
    }
    .section::before {
        z-index: -1;
        content: '';
        position: absolute;
        top: -3px;
        right: -3px;
        bottom: -3px;
        left: -3px;
        filter: blur(3px) brightness(60%);
        background: url(../assets/images/bg.jpg) no-repeat center / cover fixed;
    }
}

*/

@supports ((background-clip: text) or (-webkit-background-clip: text)) and (-webkit-text-fill-color: transparent){
    .webp.no-jpeg2000 .text_texture.crystal {
        background-image: url('../assets/images/textures/crystal.webp');
    }
    .jpeg2000 .text_texture.crystal {
        background-image: url('../assets/images/textures/crystal.jp2');
    }
    .no-webp.webp.no-jpeg2000 .text_texture.crystal {
        background-image: url('../assets/images/textures/crystal.png');
    }
    
    .text_texture.crystal {
        opacity: .9;
        background-repeat: repeat;
        background-color: #ddddff;
        background-size: 1em 1em;
        -webkit-background-clip: text;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        filter: brightness(1.06) drop-shadow(1px 1px 3px rgba(10, 10, 10, .7));
        font-weight: 600;
    }
    a.text_texture.crystal, .text_texture.crystal a {
        -webkit-text-fill-color: rgba(0, 200, 255, .3);
        display: inline-block;
        text-decoration: none;
    }
    .section h2.text_texture.crystal {
        -webkit-text-fill-color: rgba(0, 180, 255, 0.3);
    }
}

.light_theme .section h2.text_texture.text_texture.crystal {
    text-shadow: 0 0 0 white;
    color:  rgba(0, 180, 255, 1);
    -webkit-text-fill-color: rgba(0, 180, 255, 1);
    text-fill-color: rgba(0, 180, 255, 1);
}



@supports not (((background-clip: text) or (-webkit-background-clip: text)) and (-webkit-text-fill-color: transparent)) {
    .text_texture.crystal {
        color: white;
    }
    a.text_texture.crystal, .text_texture.crystal a  {
        color: blue;
    }
}
.light_theme a.text_texture.crystal, .light_theme .text_texture.crystal a {
    -webkit-text-fill-color: unset;
    text-fill-color: unset;
    color:rgba(0, 180, 255, 1);
}

.light_theme .text_texture.crystal {
    background: none !important;
    color: black;
    -webkit-text-fill-color: unset;
    -webkit-background-clip: unset;
    background-clip: unset;
    filter: none;
    text-shadow: none;
}
.light_theme .light_theme .text_texture.crystal {
    text-shadow: none;
}

@media (max-width: 1024px) {
    .section {
        width: 90%;
    }
}
@media (max-width: 600px) {
    .section {
        width: 95%;
    }
}
.section h2 {
    margin: .1rem 0;
}


.outer_wrapper {
    box-sizing: border-box;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    margin: 0;
    margin-left: 5px;
}

.outer_wrapper::-webkit-scrollbar {
    width: 6px;
}
   
.outer_wrapper::-webkit-scrollbar-thumb {
    background: rgba(160, 160, 160, .1);
    transition: ease-in-out .6s all;
    border-radius: 3px;
}
.outer_wrapper::-webkit-scrollbar-thumb:hover {
    background: rgba(160, 160, 160, .5); 
}

.ani_wrap {
    position: relative;

}
.ani_item {
    position:absolute;
    top:0;
    width: 100%;
    padding-bottom: 1rem;
    animation: fade 1s ease-in-out 1s;
    animation-fill-mode: both;
}

 
/* @keyframes fade {
    0% {
        opacity: 0;
        height: 0;
        overflow: hidden;
    }
    0.01% {
        height: auto;
        overflow: visible;
    }
    100% {
        opacity: 1;
    }
}

.fade-enter {
    opacity: 0;
    transition: all ease 1s;
    transition-delay: .5s;
}
.fade-enter.fade-enter-active {
    opacity: 1;
}


.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;
    transition: all ease .5s;
    
} */



.ani_item  {
    animation: a-routeFadeIn 0.5s ease 0.3s;
    animation-fill-mode: both;
    transform: translateY(0);
    opacity:1;
    will-change: transform,opacity;
}
 
@keyframes a-routeFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
 
.ani_item.fade-exit {
    animation: none;
    animation-fill-mode: none;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}
 
.ani_item.fade-exit-active {
    opacity: 0;
}

/**
 * AURAKA 點陣宋
 */
 @font-face {
    font-family: 'Auraka';
    src: url('../assets/fonts/Auraka.ttf');
}

/* 
    DOS, 英數
*/
@font-face {
    font-family: 'DOS';
    src: url('../assets/fonts/Fixedsys500c.woff') format('woff'),
         url('../assets/fonts/Fixedsys500c.ttf')  format('truetype'),
         url('../assets/fonts/Fixedsys500c.svg')  format('svg');
}


body.warning {
    animation: bodyWarning 1s cubic-bezier(0.51, 0.13, 0.57, 0.96) 0s infinite both;
}

@keyframes bodyWarning {
    0% {
        box-shadow: inset 0 0 20px red;
    }
    50% {
        box-shadow: inset 0 0 120px red;
    }
    100% {
        box-shadow: inset 0 0 20px red;
    }

}